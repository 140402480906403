import PersonalPicture from "../Assets/profile-1x.jpg";
import PersonalPicture2x from "../Assets/profile-2x.jpg"
import BlogFeed from "../Components/BlogFeed";
import { useEffect, useState } from "react";

const Home = () => {
    const [skills, setSkill] = useState<string[]>([
        "ASP.NET", "Web", "C#", "React", 
    ]);
    const [skillIsChanging, setSkillIsChanging] = useState<boolean>(false);
    const skillTimeout = 3000;
    const animationTimeout = 1000;

    function getNextSkill(): string {
        const firstSkill = skills.shift() as string;
        setSkill(() => [...skills, firstSkill]);
        return firstSkill;
    }

    useEffect(() => {
        return () => {
            setSkill([])
        }
    }, [])

    useEffect(() => {
        handleChange().then(() => getNextSkill())

        return setSkillIsChanging(false);
    }, [skills])

    const handleChange = async () => {
        setSkillIsChanging(false)
        await wait(skillTimeout, () => setSkillIsChanging(true));
        await wait(animationTimeout);
    }

    const wait = (waitTime: number, func?: () => void) => new Promise<void>((resolve: () => void) => {
        window.setTimeout(() => {
            if (func)
                func();

            resolve();
        }, waitTime)
    });

    return (
        <>
            <section id="intro">
                <picture id="hero">
                    <img
                        src={PersonalPicture}
                        srcSet={`${PersonalPicture} 1x, ${PersonalPicture2x} 2x`}
                        alt="Picture of Michael" height="255" width="255"></img>
                </picture>
                <h1 className="title">
                    I'm a
                    <div className={`intro-emph`} data-skill={skills[0]}>
                        <span className="intro-emph-bounds">ASP.NET</span>
                        <span className={`intro-emph-text${skillIsChanging ? " is-changing" : ""}`}>{skills[0]}</span>
                    </div>Developer, Hobbyist Photographer, and a helluvagood tinkerer.
                </h1>
            </section>
            <BlogFeed title="Recent Thoughts" limit={10} />
        </>
    );
};

export default Home;
