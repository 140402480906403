import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BlogEntry } from "../Utility/Api";
import { useBlogClient } from "../Utility/ApiHelper";
import { RightArrow } from "./Icons";

interface BlogFeedProperties {
	limit: number | null;
	title: string;
}

const BlogFeed: React.FC<BlogFeedProperties> = ({ limit, title }) => {
	const [blogs, setBlogs] = useState<BlogEntry[] | null>([]);
	const client = useBlogClient();
	const { pathname } = useLocation();

	useEffect(() => {
		client.getBlogEntries(limit).then((x) => setBlogs(x));

		return setBlogs([]);
	}, []);

	return (
		<section id="blog-feed">
			<h2 className="title">{title}</h2>
			{blogs != null &&
				blogs.map((x, i) => (
					<article className="blog-feed-article" key={x.url}>
						<Link to={`/blog/${x.url}`} state={{ previousPath: pathname }} className="blog-feed-link">
							<div className="blog-feed-title-row">
								<h2>{x.title}</h2>
							</div>

							<p className="blog-feed-date">{x.date.toISOString().split("T")[0]}</p>
							<p>{x.description}</p>
							<div className="blog-feed-tags">
								{x.tags?.map((tag) => (
									<span key={tag} className="blog-feed-tag">
										{tag}
									</span>
								))}
							</div>
							<div className="blog-feed-read-more">
								Read More <RightArrow className="blog-feed-read-more-arrow" />
							</div>
						</Link>
					</article>
				))}
		</section>
	);
};

export default BlogFeed;
