import { useEffect, useState } from "react";

interface LazyImageProps {
	src: string;
	height: number;
	width: number;
    alt: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, height, width, alt }) => {
    const [loaded, setLoaded] = useState(false);
    const placeHolder = () => `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
    
    useEffect(() => {
        setLoaded(true);
    }, []);

    return  (
        <img src={loaded ? src : placeHolder()} data-src={placeHolder()} alt={alt} loading="lazy" />
    )
};

export default LazyImage;
