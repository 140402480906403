import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./Styles/main.scss";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import Archive from "./Pages/Archive";
import ScrollToTop from "./Components/ScrollToTop";
import About from "./Pages/About";
import Footer from "./Components/Footer";


function App() {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <main id="content">
                <Routes>
                    <Route path="/about" element={<About />} />
                    <Route path="/archive" element={<Archive />} />
                    <Route path="/blog/:query" element={<Blog />} />
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
            </main>
            <Footer />
        </Router>
    );
}

export default App;
