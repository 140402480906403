import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BlogEntry } from "../Utility/Api";
import "../Styles/blog.scss";
import { useBlogClient } from "../Utility/ApiHelper";
import { LeftArrow } from "../Components/Icons";

const Blog = () => {
	const [blog, setBlog] = useState<BlogEntry | null>(null);
	const blogClient = useBlogClient();
	const navigate = useNavigate();
	const { key: keyLocation } = useLocation();

	let { query } = useParams();

	useEffect(() => {
		blogClient
			.getBlogEntry(query as string)
			.then((blog) => setBlog(blog))
			.catch(() => {
				navigate("/");
			});
	}, []);

	const handleBack = () => {
		const isInitialLocation: boolean = keyLocation === "default"; // <-- This value is always "default" on the initial location.
		const to: any = isInitialLocation ? "/" : -1;
		navigate(to);
	};

	return (
		<article className="blog-article">
			{blog && (
				<>
					<header className="blog-header">
						<h3>{blog?.title}</h3>
						<div className="blog-date">Last Updated on {blog?.lastUpdated.toLocaleDateString("en-us")}</div>
					</header>
					<div className="blog-content" dangerouslySetInnerHTML={{ __html: blog?.body }}></div>
					<button className="blog-back-button" onClick={handleBack}>
						<LeftArrow className="blog-back-button-arrow" />
						Back
					</button>
				</>
			)}
		</article>
	);
};

export default Blog;
