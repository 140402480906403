import HeroImage from "../Assets/about-me.jpg";
import LazyImage from "../Components/LazyImage";

const About = () => {
	return (
		<>
			<LazyImage src={HeroImage} alt="Hero Image. Clouds rolling above a hill." width={1389} height={829} />
			<h2>Hey!</h2>
			<p>
				I'm a software engineer with experience in web application design and development, specializing in cloud environments. I created my first
				website in 1998 with a website building service like Angelfire, &lt;marquee&gt; and all, and have been in love with web development ever
				since. I am a natural troubleshooter, tinkerer, and a lifelong learner.
			</p>
			<p>In my free time I like to roll out fresh pasta and roam around Philadelphia with my camera.</p>
			<p>
				If you'd like to reach out to me, feel free to shoot me a line at{" "}
				<a style={{ textDecoration: "underline" }} href="mailto:hello@michaelwiedinmyer.com">
					hello@michaelwiedinmyer.com
				</a>{" "}
				📨
			</p>
			<p>
				<a href="/resume/MichaelWiedinmyer.pdf" target="_blank" style={{ textDecoration: "underline" }}>
					Looking for my resume?
				</a>
			</p>
		</>
	);
};

export default About;
