import Navigation from './Navigation'
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header id="header">
            <Link to="/">
                <h2></h2>
            </Link>
            <Navigation />
        </header>
    )
}

export default Header;