const Footer = () => {
    const getDate = () => {
        const date = new Date();
        return date.getFullYear();
    };
    return (
        <footer>
            © {getDate()} Michael Wiedinmyer
            <ul>
                <li>
                    <a href="https://linkedin.com/in/michaelwiedinmyer" target="_blank">LinkedIn</a>
                </li>
                <li>
                    <a href="https://github.com/MichaelWiedinmyer" target="_blank">Github</a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
