import BlogFeed from "../Components/BlogFeed";

const Archive: React.FC = () => {
    return (
        <>
            <BlogFeed title="Archive" limit={null} />
        </>
    )
}

export default Archive;